import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeleteConfirm from "../../../components/DeleteConfirm";
// import Mqtt from "../../../Mqtt/Mqtt";
import NetInfo from "../../../NetInfo/NetInfox";

import ListGroup from "./Modals/Group/ListGroup";
import ShowImage from "./Modals/ShowImage/ShowImage";
// import AddBulkResident from "./Modals/Users/AddBulkResident";
import AddUser from "./Modals/Users/AddUser";
// import ChangePassword from "./Modals/Users/ChangePassword";
// import ListResidents from "./Modals/Users/ListResidents";
import ListUser from "./Modals/Users/ListUsers";
import ActiveComplaintList from "../SideBar/ActiveComplaintList";
import ActiveComplaints from "./Modals/Complaints/ActiveComplaints";
import ComplaintHistory from "./Modals/Complaints/SearchComplaints";
import ComplaintDetails from "./Modals/Complaints/ComplaintDetails";
import {
  AssignUnattandedComplaintToOpr,
  AssignUnattandedComplaintToSup,
} from "../../../actions";
import UserDetails from "./Modals/Users/UserDetails";
import ConfigDB from "./Modals/Config/Config";
import ChangePassword from "./Modals/Users/ChangePassword";
import UpdateProfile from "./Modals/Users/UpdateProfile";
import ListResidents from "./Modals/Users/ListResidents";
import AddCustomer from "./Modals/Users/AddCustomer";
import AddEfig from "./Modals/EFIG/AddEfig";
import ListEfig from "./Modals/EFIG/ListEfig";
import AssignEfig from "./Modals/EFIG/AssignEfig";
import InterGroupTransfer from "./Modals/EFIG/InterGroupTransfer";
import CreateAnn from "./Modals/Announcement/CreateAnn";
import ListAnn from "./Modals/Announcement/ListAnn";
import EfficiencyReport from "./Modals/Reports/EfficiencyReport";
import AddGroup from "./Modals/Group/AddGroup";
import ComplaintDetailsMain from "./Modals/Complaints/ComplaintDetailsMain";
import EfigDetails from "./Modals/EFIG/EfigDetails";
import EfigAlarmDetails from "./Modals/EFIG/AlarmDetails";
import CustomerDetails from "./Modals/Users/CustomerDetails";
import AddErpComplaint from "./Modals/Complaints/AddErpComplaint";
import ViewLocation from "./Modals/Map/ViewLocation";
import AddPayment from "./Modals/Payment/AddPayment";
import ListPayment from "./Modals/Payment/List";
import UpdateFWAll from "./Modals/EFIG/UpdateFWAll";
import UpdateUserAccessControl from "./Modals/Users/UsersAccessControl";
import UpdateEfig from "./Modals/EFIG/UpdateEfig";
import EfigLogDetails from "./Modals/EFIG/LogDetails";
// import UpdateProfile from "./Modals/Users/UpdateProfile";

const MapWall = (props) => {
  const user = useSelector((state) => state.user.user);
  const screenWidth = useSelector((state) => state.theme.screenWidth);

  useEffect(() => {
    setTimeout(() => {
      if (user.roleId === 17) {
        AssignUnattandedComplaintToSup();
      } else if (user.roleId === 18) {
        AssignUnattandedComplaintToOpr();
      }
    }, 3000);
  }, [user]);

  return (
    <>
      <div style={{ width: "100%", height: "90vh", overflowY: "auto" }}>
        {/* <MapItems /> */}
        {/* <MapGl /> */}
        {screenWidth?.width >= 1000 ? <ComplaintDetailsMain /> : null}
        <AddUser />
        <ListUser />
        {/* <ManageMap /> */}
        <ListGroup />
        <DeleteConfirm />
        {/* <ActiveComplaints /> */}
        <ComplaintHistory />
        <ComplaintDetails />
        <UserDetails />
        <NetInfo />
        {/* <SatffAttandance /> */}
        {/* <ActiveStaff />  */}
        <AddCustomer />
        <ChangePassword />
        <UpdateProfile />
        <ListResidents />
        <AddEfig />
        <ListEfig />
        <AssignEfig />
        <UpdateEfig />
        <AddGroup />
        <InterGroupTransfer />
        <CreateAnn />
        <ListAnn />
        <EfficiencyReport />
        <EfigDetails />
        <EfigAlarmDetails />
        <EfigLogDetails />
        <CustomerDetails />
        <AddErpComplaint />
        <ViewLocation />
        <AddPayment />
        <ListPayment />
        <UpdateFWAll />
        <UpdateUserAccessControl />
        {/* {user.roleId === 1 || user.roleId === 15 || user.roleId === 13 ? (
          <>
            <SatffAttandance />
            <CreateAnn />
            <ListResidents />
          </>
        ) : null} */}

        {/* Users */}
        {/* {user.roleId === 1 ? (
          <>
            
            <AddBulkResident />
            <AddGeofence />
            <ViewMarkerWithGeofence />
            <AddDevice />
            <AddGroup />
            <RegisterationRequest />
            <RejectedRequest />
            <LocateDevice />
          </>
        ) : null}
        {user.roleId !== 7 ? <CreateAnnForRes /> : null}
         */}

        {/* <ViewLocation />
        
        <ConfigDB />
        <AlertHistory />
        <AlertDetails />

         */}

        {/* Maps */}
        {/* <ViewGeofence /> */}

        {/* Announcements */}
        {/* <ListAnn />
        <MqttStatus />

        <ListDevices />
        
        <EventPlayback />
        <Playback /> */}

        {/* Activity Log */}
        {/* <ActiveStaff />
        <ActiveAlerts /> */}

        {/* <Mqtt /> */}
        {/* 
        <TabChange />*/}
        <ShowImage />
        {/* <EfficiencyReport /> */}
      </div>
    </>
  );
};

export default MapWall;
