/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";

import {
  setEfig,
  setEfigLoading,
  setUpdate,
} from "../../../../../redux/slices/efigSlice";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";
import { EFIGAlarmDetailsApi } from "../../../../../actions";
TimeAgo.addLocale(en);
var arr = [];

const EfigLogDetails = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const [fw, setFw] = useState("");

  // const  = useSelector((state) => state.efig.efig);
  const efig = useSelector((state) => state.efig.tempEfig);
  const update = useSelector((state) => state.efig.update);
  const alarmHistory = useSelector((state) => state.efig.logHistory);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (efig && user && update) {
      getAlarmHistory();
    }
  }, [efig, user, props, update]);

  const getAlarmHistory = async () => {
    // dispatch(setEfigLoading(true));
    if (efig?.userId && efig?.id) {
      await EFIGAlarmDetailsApi(dispatch, {
        userId: efig?.userId,
        energizerId: efig?.id,
      });
    }
    dispatch(setEfigLoading(false));
  };

  const getList = () => {
    console.log("debug list ::: ", alarmHistory);
    return alarmHistory?.map((val) => (
      <tr key={val.id}>
        <td>{val?.des}</td>
        <td>
          {new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Modal
        title={"EFIG Arm/Disarm Details"}
        name={"efigLogDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        {alarmHistory ? (
          <>
            <div className="col-12">
              <a href="#" className="nav-link">
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To List"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#efigDetails"}
                  onClick={() => {
                    dispatch(setEfig(efig));
                    dispatch(setUpdate(false));
                  }}
                ></i>
              </a>
            </div>
            <div className="row">
              <div
                className="col-lg-12"
                style={{
                  height: "68vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center className="sticky">
                  <u>
                    <h5>Arm/Disarm Log</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>{getList()}</tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default EfigLogDetails;
