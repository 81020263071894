import axios from "axios";
import { URL } from "./../config/index";
import { api } from "./apiClient";
// import { alertUnSubscribe } from "../components/Project/Dashboard/Admin/Mqtt/Mqtt";
import {
  setAdmins,
  setAnnouncements,
  setOperators,
  setSearchedOperators,
  setSearchedServiceContacts,
  setSearchedSupervisors,
  setService,
  setServiceContacts,
  setSupervisors,
  setUser,
  // setConfig,
  setRefresh,
  setInternetStatus,
  setSearchedGroups,
  setGroups,
  setConfig,
  setCustomers,
  setWhiteList,
  setWpSearchedUsers,
  setWpUsers,
  setCustomerMembers,
  setTechnicians,
  setSync,
} from "../redux/slices/userSlice";
import { errorToast, succesToast } from "../helpers/toasts";
import {
  setChatbox,
  setChatType,
  setComplaintChatBadges,
  setComplaintChatMsgs,
  setComplaintChatSingle,
  setNewBadges,
  setOpenedChatbox,
  setOpenedChatboxType,
} from "../redux/slices/chatSlice";
import {
  setFocusedMarker,
  setGeoLatLng,
  setLatLng,
  setStaticMarker,
  setZoom,
} from "../redux/slices/mapSlice";
import {
  setActiveCivilComplaints,
  setActiveComplaintDataChart,
  setActiveComplaintList,
  setActiveElectricComplaints,
  setActiveMiscComplaints,
  setActivePlumbingComplaints,
  setActiveSanitaryComplaints,
  setAllResponderAssignments,
  setChartComplaintList,
  setCivilTabBeat,
  setClosedComplaintDataChart,
  setComplaintList,
  setElectricTabBeat,
  setErpComplaintData,
  setIsResolveLoading,
  setMiscTabBeat,
  setPlumbingTabBeat,
  setResponderAssignmentLoading,
  setResponderAssignments,
  setSanitaryTabBeat,
  setSearchedActiveComplaintList,
  setTotalComplaintDataChart,
} from "../redux/slices/complaintSlice";
// import { setAttandance } from "../redux/slices/logsSlice";
import { toast } from "react-toastify";
import { setAttandance } from "../redux/slices/logsSlice";
import {
  setAlarmHistory,
  setEfigList,
  setLogHistory,
  setPaymentList,
  setStaticList,
} from "../redux/slices/efigSlice";
import {
  setErpCustomersList,
  setErpProjectList,
  setErpTechList,
  setErpUsersList,
} from "../redux/slices/erpSlice";
import { setList } from "../redux/slices/paymentSlice";

// const dispatch = useDispatch();

// Fetch All Staff

export const ChangeUserPassword = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/changePassword";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Password changed.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Change password excwption", e);
    });
};

export const DeleteServiceContact = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/deleteComplaintServiceContact";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User Deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" Delete User error ", e);
      throw new Error("Error");
    });
};

export const SearchStaffAttandance = async (dispatch, props) => {
  try {
    const token = localStorage.getItem("token");
    const url = URL + "logs/staffAttandance";
    await api
      .post(url, props, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        dispatch(setAttandance(response.data));
      })
      .catch((e) => {
        errorToast(e.response.data.err);
        console.error("updateUser Error", e);
        throw new Error("Error");
      });
  } catch (error) {
    console.error("attandance error", error);
  }
};

export const SearchActiveComplaint = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/searchActive";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setSearchedActiveComplaintList(data));
    })
    .catch((e) => {
      console.error("Search Active complaint Expections");
      errorToast(e.response.data.err);
    });
};

export const DiscardComplaint = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/discard";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      return data;
      // dispatch(setSearchedActiveComplaintList(data));
    })
    .catch((e) => {
      console.error("Discard complaint Expections");
      errorToast(e.response.data.err);
    });
};

export const VerifyEmailAPI = async (dispatch, data) => {
  const url = URL + "user/verifyEmail";
  await api
    .post(url, data)
    .then((response) => {
      succesToast("Email Verified");
    })
    .catch((e) => {
      errorToast(e.response?.data?.err || "Something went wrong");
      throw new Error(e);
    });
};

export const AddNewComplaintChatMsg = async (dispatch, newMsg, msgList) => {
  const newList = [...msgList];
  newList.push(newMsg);
  dispatch(setComplaintChatMsgs(newList));
};

export const UpdateComplaintChatMsg = async (dispatch, data, msgList) => {
  const newList = msgList.map((msg) => {
    if (msg.uuid === data.uuid) {
      return {
        ...msg,
        msgStage: data.msgStage > msg.msgStage ? data.msgStage : msg.msgStage,
        cusReadState:
          data.cusReadState > msg.cusReadState
            ? data.cusReadState
            : msg.cusReadState,
        ackSent: data.ackSent,
      };
    }
    return msg;
  });
  dispatch(setComplaintChatMsgs(newList));
};

export const UserLogin = async (dispatch, props) => {
  try {
    var url = URL + "user/loginVps";
    var data = {
      contact: "+92" + props.contact,
      password: props.password,
    };

    const res = await axios.post(url, data);
    if (!res.data.token) {
      throw new Error("Token not recieved");
    }

    res.data.user.accessControl = res.data.accessControl;
    console.log("asdsadasdsadasdas", res.data);

    localStorage.removeItem("token");
    localStorage.setItem("token", res.data.token);
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    localStorage.setItem("refreshToken", res.data.refreshToken);
    localStorage.setItem("userData", JSON.stringify(res.data.user));
    localStorage.setItem("user", JSON.stringify(res.data.user));

    dispatch(setUser(res.data.user));
  } catch (e) {
    errorToast(JSON.stringify(e.response?.data?.err));
    console.error(e);
  }
};

export const changeZoom = (dispatch, data) => {
  dispatch(setZoom(data));
};

export const GetCenter = (dispatch, data) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  var url = URL + "geo/find-center";
  api
    .post(url, { data }, headers)
    .then(async (response) => {
      var info = response.data.result.data;
      let lat = Math.random() / 100000;
      let lng = Math.random() / 100000;
      lat = info.latitude + lat;
      lng = info.longitude + lng;
      dispatch(
        setLatLng({
          latitude: lat,
          longitude: lng,
        })
      );
    })
    .catch((e) => {
      // console.log(e);
    });
};

export const ChangeGeoLatLng = (dispatch, data) => {
  dispatch(setGeoLatLng(data));
};

export const OperatorAcceptComplaint = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/operatorAcceptComplaint";
  await api
    .post(url, props, headers)
    .then((response) => {
      dispatch(setIsResolveLoading(false));
    })
    .catch((e) => {
      dispatch(setIsResolveLoading(false));
      errorToast(e?.response?.data?.err || e.err);
      console.error("Accept error", e);
      throw new Error("Error");
    });
};

export const OperatorAssignComplaintToRes = async (dispatch, id, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/operatorAssignComplaintToRes";
  props.complaintId = id;
  await api
    .post(url, props, headers)
    .then((res) => {
      succesToast("Complaint assigned to responder.");
      GetActiveResponderAssignments(dispatch);
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const UpdateDBConfig = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "config/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // succesToast("Config Updated");
      return response;
    })
    .catch((e) => {
      errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const GetDBConfig = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "config";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch(setConfig(response.data));
    })
    .catch((e) => {
      // errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const UserResolveComplaint = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/userResolveComplaint";
  await api
    .post(url, props, headers)
    .then((res) => {
      return res.data;
      // succesToast("Complaint marked as resolved.");
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const UserCloseComplaint = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/userCloseComplaint";
  await api
    .post(url, props, headers)
    .then((res) => {
      return res.data;
      // succesToast("Complaint Closed.");
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const GenerateAndSendPassword = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/generateAndSendPassword";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const SetChatbox = (dispatch, props) => {
  dispatch(setChatbox(props));
};

export const SetOpenedChatbox = (dispatch, props) => {
  dispatch(setOpenedChatbox(props));
};

export const SetOpenedChatboxType = (dispatch, props) => {
  dispatch(setOpenedChatboxType(props));
};

export const SetNewBadges = (dispatch, props) => {
  dispatch(setNewBadges(props));
};

export const SetChatType = (dispatch, props) => {
  dispatch(setChatType(props));
};

export const SetRefresh = (dispatch, props) => {
  dispatch(setRefresh(props));
};

export const SetInternetState = (dispatch, props) => {
  dispatch(setInternetStatus(props));
};

export const FetchComplaintChat = async (dispatch) => {
  // setIsLoading(true);
  const t = localStorage.getItem("token");
  const url = URL + "chat-logs/complaintChat";
  api
    .get(url, {
      headers: {
        Authorization: "Bearer " + t,
      },
    })
    .then((response) => {
      console.log("response data", response.data);
      if (response.data) {
        dispatch(setComplaintChatMsgs(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Free Chat Expection", e);
    });
};

export const FetchComplaintChatSingle = async (dispatch, props) => {
  // setIsLoading(true);
  const t = localStorage.getItem("token");
  const url = URL + "chat-logs/complaintChatSingle";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + t,
      },
    })
    .then((response) => {
      if (response.data) {
        if (response.data.length === 0) {
          errorToast("No Chat Record Found");
        }
        dispatch(setComplaintChatSingle(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Free Chat Single Expection", e);
      throw new Error(e);
    });
};

export const UpdateResponder = (dispatch, res, resList) => {
  const newList = [];
  for (let i = 0; i < resList.length; i++) {
    const s = { ...resList[i] };
    if (s.id === res.id) {
      newList.push(res);
    } else {
      newList.push(s);
    }
  }
};

export const UpdateOperator = (dispatch, opr, oprList) => {
  const newList = [];
  for (let i = 0; i < oprList.length; i++) {
    const o = oprList[i];
    if (o.id === opr.id) {
      newList.push(opr);
    } else {
      newList.push(o);
    }
  }
  dispatch(setOperators(newList));
};

export const UpdateSupervisor = (dispatch, sup, supList) => {
  const newList = [];
  for (let i = 0; i < supList.length; i++) {
    const s = supList[i];
    if (s.id === sup.id) {
      newList.push(sup);
    } else {
      newList.push(s);
    }
  }
  dispatch(setSupervisors(newList));
};

export const SetComplaintChatBadges = (dispatch, props) => {
  dispatch(setComplaintChatBadges(props));
};

// TODO

export const SetFocusedMarker = (dispatch, props) => {
  dispatch(setFocusedMarker(props));
};

export const GetServiceDetails = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "service/one";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch(setService(response.data));
    })
    .catch((e) => {
      // errorToast(e.response?.data?.err);
      // console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const AddComplaintResponder = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/addComplaintResponder";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "User Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding user error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateComplaintResponder = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateComplaintResponder";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "User Updated");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("updating user error");
      }

      console.error("update user Error", e);
      throw new Error("Error");
    });
};

export const AddMarker = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaintMarker";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Marker Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding marker error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateMarker = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaintMarker/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Marker Updated");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("updating marker error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const GetMarkers = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaintMarker";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setStaticMarker(data));
    })
    .catch((e) => {
      console.error("Get Marker Expections");
    });
};

export const DelMarker = async (dispatch, markerId = 0) => {
  const token = localStorage.getItem("token");
  var url = URL + "complaintMarker/delete";
  let data = {
    markerId,
  };
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  await api
    .post(url, data, headers)
    .then((result) => {
      succesToast("Marker Deleted successfully");
    })
    .catch((e) => {
      errorToast("Marker deleting Error");
      // console.log("Error deleted Marker", e);
      throw new Error("Error");
    });
};

export const UpdateUserProfile = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateProfile";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
      localStorage.setItem("userData", JSON.stringify(response.data));
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch(setUser(response.data));
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("update User Error", e);
      throw new Error("Error");
    });
};

// export const SearchPanics = async (dispatch, props = {}) => {
//   try {
//     const token = localStorage.getItem("token");
//     const url = URL + "panic/search";
//     await api
//       .post(url, props, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       })
//       .then(async (response) => {
//         // alert("here");
//         let data = response.data;
//         let type2Alerts = data.filter((item) => item.alertType === 2);

//         let allCusPanics = data.filter((item) => item.alertType !== 2);
//         dispatch(setSearchedType2Alerts(type2Alerts));
//         dispatch(setSearchedCusAlerts(allCusPanics));
//       })
//       .catch((e) => {
//         alert(JSON.stringify(e.response));
//         errorToast(e.response.data.err || "Something went wrong");
//         console.error("Get Devices Expections", e);
//       });

//     // const response = await apiClient.getAlerts();

//     // let data = response.data;
//     // let type2Alerts = data.filter((item) => item.alertType === 2);

//     // let allCusPanics = data.filter((item) => item.alertType !== 2);

//     // // console.log("alerts >>>>>>>>>>>>>.", allCusPanics, type2Alerts, data);

//     // dispatch(setSearchedType2Alerts(type2Alerts));
//     // dispatch(setSearchedCusAlerts(allCusPanics));
//   } catch (error) {
//     console.error("panic retrive error", error);
//   }
// };

export const SupervisorAcceptComplaint = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/supervisorAcceptComplaint";
  await api
    .post(url, props, headers)
    .then((response) => {
      dispatch(setIsResolveLoading(false));
    })
    .catch((e) => {
      dispatch(setIsResolveLoading(false));
      throw new Error(e);
    });
};

export const SupervisorAssignComplaintToRes = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/supervisorAssignComplaintToRes";

  await api
    .post(url, props, headers)
    .then(async (response) => {
      await GetActiveResponderAssignments(dispatch);
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const PutComplaintOnHold = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/putComplaintOnHold";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const ChangePreferedDateTime = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/changePreferedDateTime";

  await api
    .post(url, props, headers)
    .then((response) => {
      GetActiveResponderAssignments(dispatch);
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const DeleteResponderAssignment = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/deleteResponderAssignment";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const ResponderAssignments = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/responderAssignments";

  await api
    .post(url, props, headers)
    .then((response) => {
      console.log("sadasdsadasdsad", response.data);
      dispatch(setResponderAssignments(response.data));
      dispatch(setResponderAssignmentLoading(false));
    })
    .catch((e) => {
      dispatch(setResponderAssignmentLoading(false));
      // throw new Error(e);
    });
};

export const GetActiveResponderAssignments = async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/getActiveResponderAssignments";

  await api
    .get(url, headers)
    .then((response) => {
      dispatch(setAllResponderAssignments(response.data));
      dispatch(setResponderAssignmentLoading(false));
    })
    .catch((e) => {
      dispatch(setResponderAssignmentLoading(false));
      // throw new Error(e);
    });
};

export const AssignUnattandedComplaintToSup = async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/assignUnattandedComplaintToSup";

  await api
    .post(url, headers)
    .then((response) => {
      // dispatch(setAllResponderAssignments(response.data));
      // dispatch(setResponderAssignmentLoading(false));
      // succesToast("Assigned to sup");
    })
    .catch((e) => {
      // dispatch(setResponderAssignmentLoading(false));
      // throw new Error(e);
      console.error(e.response.data.err);
    });
};

export const AssignUnattandedComplaintToOpr = async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/assignUnattandedComplaintToOpr";

  await api
    .post(url, headers)
    .then((response) => {
      // dispatch(setAllResponderAssignments(response.data));
      // dispatch(setResponderAssignmentLoading(false));
      // succesToast("Assigned to Opr");
    })
    .catch((e) => {
      // dispatch(setResponderAssignmentLoading(false));
      // throw new Error(e);
      console.error(e.response.data.err);
    });
};

export const UnHoldComplaint = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/unHoldComplaint";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const SupervisorAssignComplaintToOpr = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "complaint/supervisorAssignComplaintToOpr";
  await api
    .put(url, props, headers)
    .then(async (response) => {
      // console.log("sasadasdasdsadasdsad", response.data);
      await GetActiveResponderAssignments(dispatch);
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const AddAnnouncement = (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement";
  api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Announcement Added.");
    })
    .catch((e) => {
      errorToast("Adding Announcement exception.");

      console.error("Adding Announcement exception.", e);
    });
};

export const BroadCastForResponder = async (props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/responder";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const UpdateServiceContact = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateServiceContact";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const AddContact = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/addServiceContact";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Service contact created successfully.");
    })
    .catch((e) => {
      // console.warn("ddddddddddddddddddddddddddddd serveice", e);
      if (e.response.data.err) {
        errorToast(JSON.stringify(e.response.data.err));
      }
      console.error("Service Contact adding exception.", e);
      throw new Error("Error");
    });
};

// *************************************************************************************************//

export const UploadFile = async (file, type) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("file", file);
  data.append("storageType", type);

  var config = {
    method: "post",
    url: URL + "upload",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    data: data,
  };
  const res = await axios(config);
  return res.data;

  // .then(function (response) {
  //   console.log("file uploaded", response.data);
  //   return response.data;
  // })
  // .catch(function (error) {
  //   errorToast("Upload image error");
  //   console.log(error);
  //   throw new Error("Error");
  // });
};

export const UpdateUserInfo = async (dispatch, props, user) => {
  const newUserData = { ...user };
  const token = localStorage.getItem("token");
  const url = URL + "user/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("rrrrrrr", response.data);
      newUserData.isFirstLogin = false;
      localStorage.removeItem("userData");
      localStorage.removeItem("user");
      localStorage.setItem("userData", JSON.stringify(newUserData));
      localStorage.setItem("user", JSON.stringify(newUserData));
      dispatch(setUser(newUserData));
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const GetActiveComplaints = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/active";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;
      // const electric = data.filter((val) => val.title == 1);
      // const civil = data.filter((val) => val.title == 2);
      // const sanitary = data.filter((val) => val.title == 3);
      // const plumbing = data.filter((val) => val.title == 4);
      // const misc = data.filter((val) => val.title == 5);
      dispatch(setActiveComplaintList(data));
    })
    .catch((e) => {
      errorToast("Error Getting Complaints");
      console.error("Get Complaints Expections", e);
    });
};

export const GetUsers = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/allVps";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setWpSearchedUsers(data.users));
      dispatch(setOperators(data.operator));
      dispatch(setTechnicians(data.tech));
      dispatch(setSupervisors(data.supervisor));
      dispatch(setWpUsers(data.users));
      dispatch(setAdmins(data.admin));
    })
    .catch((e) => {
      console.error("Get Users Expections");
    });
};

export const SearchStaff = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      // console.log("sadsadasdasdasdsadsad", data.directorSecurity);
      dispatch(setWpSearchedUsers(data.users));
    })
    .catch((e) => {
      console.error("Search Users Expections");
      errorToast(e.response.data.err);
    });
};

export const AddUserAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/addUser";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "User Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding user error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const AddCustomerAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "Customer Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding user error");
      }

      console.error("addUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateUsers = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("User updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const UpdateCustomer = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/updateCustomer";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Customer updated successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("updateUser Error", e);
      throw new Error("Error");
    });
};

export const DeleteUser = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/deleteStaff";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("asdksakdhaskjdhas", response.data);
      succesToast("User Deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" Delete User error ", e);
      throw new Error("Error");
    });
};

export const GetESPLocation = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/location";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("asdksakdhaskjdhas", response.data);
      succesToast("Location Request sent");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" location request error ", e);
      throw new Error("Error");
    });
};

export const DeleteCustomer = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/deleteCustomer";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      // console.log("asdksakdhaskjdhas", response.data);
      succesToast("Customer Deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error(" Delete User error ", e);
      throw new Error("Error");
    });
};

export const SearchCustomers = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/searchCustomer";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setCustomers(data));
    })
    .catch((e) => {
      console.error("Get Users Expections", e);
    });
};

export const GetMembers = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/getAllMembers";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setCustomerMembers(data));
    })
    .catch((e) => {
      console.error("Get Members Expections", e);
    });
};

export const UnBlockUser = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/unBlockUser";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const BlockUser = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "user/blockUser";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      // console.log("block", e);
      throw new Error(e);
    });
};

export const GetAllPayments = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "payment";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setList(data));
    })
    .catch((e) => {
      console.error("Get payment Expections", e);
    });
};

export const AddPaymentAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "payment/add";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "Payment Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding Payment error");
      }

      console.error("add payment Error", e);
      throw new Error("Error");
    });
};

export const UpdatePaymentAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "payment/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "Payment Updated");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Updating Payment error");
      }

      console.error("update payment Error", e);
      throw new Error("Error");
    });
};

export const SearchPayment = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "payment/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setList(data));
    })
    .catch((e) => {
      console.error("Search payment Expections");
      errorToast(e.response.data.err);
    });
};

export const AddEFIG = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast(response.data.msg || "EFIGs Added");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Adding EFIG error");
      }

      console.error("addefig Error", e);
      throw new Error("Error");
    });
};

export const GetEFIGList = async (dispatch) => {
  const token = localStorage.getItem("token");

  const url = URL + "energizer";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      dispatch(setEfigList(response.data));
      dispatch(setStaticList(response.data));
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast(" Getting EFIG List.");
      }

      console.error("getefig Error", e);
      throw new Error("Error");
    });
};

export const DeleteEFIG = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/delete";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG deleted");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Delete EFIG error.");
      }

      console.error("delete efig Error", e);
      throw new Error("Error");
    });
};

export const SearchEFIG = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/searchQuerry";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setEfigList(data));
    })
    .catch((e) => {
      console.error("Get EFIG Serach Expections", e);
    });
};

export const AssignEFIG = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "user/assignEsp";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG Assigned");
    })
    .catch((e) => {
      // if (e.response.data.err) {
      errorToast(e.response.data.err);
      throw new Error(e);
    });
};

export const UpdateEFIGAPI = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/updateEfig";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG Updated");
    })
    .catch((e) => {
      // if (e.response.data.err) {
      errorToast(e.response.data.err);
      throw new Error(e);
    });
};

export const UnassignEFIG = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/unAssignEsp";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG un-assigned");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Un-assign EFIG error.");
      }

      console.error("Un-assign efig Error", e);
    });
};

export const UnBlockEnergizer = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "energizer/unBlockEnergizer";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const BlockEnergizer = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "energizer/blockEnergizer";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      // console.log("block", e);
      throw new Error(e);
    });
};

export const ActiveEnergizer = async (props) => {
  const token = localStorage.getItem("token");
  const headers = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const url = URL + "energizer/activeEnergizer";

  await api
    .post(url, props, headers)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      // console.log("block", e);
      throw new Error(e);
    });
};

export const UpdateFW = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/updateFirmware ";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG Update FirmWare Command Sent");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Update FW EFIG error.");
      }

      console.error("fw efig Error", e);
      throw new Error("Error");
    });
};

export const UpdateFWAll = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/updateFirmwareAll ";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIG Update FirmWare Command Sent");
    })
    .catch((e) => {
      if (e.response.data.err) {
        errorToast(e.response.data.err);
      } else {
        errorToast("Update FW EFIG error.");
      }

      console.error("fw efig Error", e);
      throw new Error("Error");
    });
};

export const EFIGAlarmDetailsApi = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "alarm/historyForWeb ";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      console.log("aaaaaaaa", response.data);
      dispatch(setAlarmHistory(response.data?.alarms));
      dispatch(setLogHistory(response.data?.logs));
      // succesToast("EFIG Update FirmWare Command Sent");
    })
    .catch((e) => {
      if (e.response.data.err) {
        // errorToast(e.response.data.err);
      } else {
        // errorToast("Update FW EFIG error.");
      }

      console.error("efig alarm log Error", e);
    });
};

export const InterGroupTransferApi = async (dispatch, props) => {
  const token = localStorage.getItem("token");
  const url = URL + "energizer/interGroupTransfer";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("EFIGs Transfered");
    })
    .catch((e) => {
      if (e.response.data.err) {
        // throw new Error(e);
        errorToast(e.response.data.err);
      } else {
        errorToast("Assign EFIG error.");
      }

      console.error("assign efig Error", e);
    });
};

export const GetAllGroups = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "group";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;

      // console.log("datatatatatata", data);

      dispatch(setGroups(data));
      dispatch(setSearchedGroups(data));
    })
    .catch((e) => {
      errorToast("Error Getting Groups");
      console.error("Get Groups Expections", e);
    });
};

export const GetAllGroupsForEfigTransfer = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "group";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;

      // console.log("datatatatatata", data);

      dispatch(setGroups(data));
      dispatch(setSearchedGroups(data));
    })
    .catch((e) => {
      errorToast("Error Getting Groups");
      console.error("Get Groups Expections", e);
    });
};

export const AddGroupAPI = async (dispatch, props) => {
  const url = URL + "group/add";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group added successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Add Group Expections", e);
      throw new Error("Error");
    });
};

export const UpdateGroupAPI = async (dispatch, props) => {
  const url = URL + "group/update";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group updated successfully.");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      console.error("Update Group Expections", e);
      throw new Error("Error");
    });
};

export const DeleteGroupAPI = async (dispatch, props) => {
  const url = URL + "group/delete";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Group deleted successfully.");
    })
    .catch((e) => {
      errorToast(e.response.data.err);
      console.error("Deleting Group Expections", e);
      throw new Error("Error");
    });
};

export const SearchGroups = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "group/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setSearchedGroups(data));
    })
    .catch((e) => {
      console.error("Get Group Expections", e);
    });
};

export const AddErpComplaintApi = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/addErpComplaint";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Complaint Added");
    })
    .catch((e) => {
      console.error("Adding complaint error", e);
      throw new Error(e);
    });
};

export const GetErpCustomers = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "erp/allCustomers";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;
      dispatch(setErpCustomersList(data));
    })
    .catch((e) => {
      errorToast("Error Getting erp sutomers");
      console.error("Get erp cus Expections", e);
      dispatch(setSync(false));
    });
};

export const GetErpTechs = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "erp/allTechs";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;
      dispatch(setErpTechList(data));
    })
    .catch((e) => {
      errorToast("Error Getting erp technicians");
      console.error("Get erp tech Expections", e);
    });
};

export const GetErpComplaintDetails = async (dispatch, data) => {
  // try {
  const url = URL + "complaint/ticket";
  await api
    .post(
      url,
      { ticketId: data.dolibarrTicketId },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then(async (response) => {
      dispatch(setErpComplaintData(response.data));
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response?.data?.err));
      // throw new Error("Error");
    });
};

export const UpdateComplaintState = async (dispatch, data) => {
  // try {
  const url = URL + "complaint/updateState";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Complaint Updated");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      // throw new Error("Error");
    });
};

export const SearchComplaint = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setComplaintList(data));
    })
    .catch((e) => {
      console.error("Search complaint Expections");
      errorToast(e.response.data.err);
    });
};

export const UpdateComplaintDetails = async (dispatch, data) => {
  // try {
  const url = URL + "complaint/updateDetails";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Complaint Updated");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      // throw new Error("Error");
    });
};

export const DeleteComplaintApi = async (dispatch, data) => {
  // try {
  const url = URL + "complaint/delete";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Complaint Deleted");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      // throw new Error("Error");
    });
};

export const UpdateComplaintStateToErp = async (dispatch, data) => {
  // try {
  const url = URL + "complaint/updateStateToErp";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async (response) => {
      succesToast("Complaint State Updated");
    })
    .catch((e) => {
      errorToast(JSON.stringify(e.response.data.err));
      // throw new Error("Error");
    });
};

export const GetErpUsers = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "erp/allUsers";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;
      dispatch(setErpUsersList(data));
    })
    .catch((e) => {
      errorToast("Error Getting erp users");
      console.error("Get erp users Expections", e);
      dispatch(setSync(false));
    });
};

export const GetErpProjects = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "erp/allProjects";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      let data = response.data;
      // console.log("data", data);
      const projList = [];
      data.forEach((proj) => {
        projList.push({
          ...proj,
          label: `${proj.ref} (${proj.label})`,
        });
      });

      dispatch(setErpProjectList(projList));
    })
    .catch((e) => {
      errorToast("Error Getting erp projects");
      console.error("Get erp proj Expections", e);
      dispatch(setSync(false));
    });
};

export const SyncProjects = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "syncProject";
  dispatch(setSync(true));
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      // window.location.reload();
      // await GetErpProjects(dispatch);
      dispatch(setSync(false));
    })
    .catch((e) => {
      errorToast("Error Sync ERP projects");
      console.error("Get erp proj Expections", e);
      dispatch(setSync(false));
    });
};

export const SyncCustomers = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "syncCustomer";
  dispatch(setSync(true));
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      // window.location.reload();
      // await GetErpCustomers(dispatch);
      dispatch(setSync(false));
    })
    .catch((e) => {
      errorToast("Error Sync ERP customers");
      console.error("Get erp proj Expections", e);
      dispatch(setSync(false));
    });
};

export const SyncUsers = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "syncUsers";
  dispatch(setSync(true));
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      // window.location.reload();
      // await GetErpUsers(dispatch);
      dispatch(setSync(false));
    })
    .catch((e) => {
      errorToast("Error Sync ERP users");
      console.error("Get erp proj Expections", e);
      dispatch(setSync(false));
    });
};

export const SyncComplaints = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "syncComplaint";
  dispatch(setSync(true));
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      dispatch(setSync(false));
      succesToast("Complaints Synced");
      // window.location.reload();
      // await GetErpUsers(dispatch);
    })
    .catch((e) => {
      errorToast("Error Sync ERP Complaints");
      console.error("Get erp complaint   Expections", e);
      dispatch(setSync(false));
    });
};

export const SyncSaleOrders = async (dispatch) => {
  // try {
  const token = localStorage.getItem("token");
  const url = URL + "syncSaleOrders";
  dispatch(setSync(true));
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(async (response) => {
      dispatch(setSync(false));
      succesToast("Sale Orders Synced");
      // window.location.reload();
      // await GetErpUsers(dispatch);
    })
    .catch((e) => {
      errorToast("Error Sync ERP SO");
      dispatch(setSync(false));
    });
};

export const UserLogout = async (dispatch, user) => {
  const token = localStorage.getItem("token");
  dispatch(setUser(null));
  localStorage.clear();
  // const url = URL + "auth/logout";
  // const headers = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };
  // await api
  //   .post(url, { userId: user.id }, headers)
  //   .then((res) => {
  //     dispatch(setUser(null));
  // localStorage.clear();
  //   return res;
  // })
  // .catch((e) => {
  //   // errorToast(e.response.data.err || "");
  //   toast.error(
  //     <>
  //       <div style={{ fontSize: "18px" }}>Something went wrong</div>
  //       <button
  //         style={{ flex: 1, width: "100%", marginTop: "8px" }}
  //         title="Force Logout"
  //         className="btn btn-sm btn-secondary"
  //         onClick={() => {
  //           // setIsLoading(true);
  //           dispatch(setUser(null));
  //           localStorage.clear();
  //           window.location.reload();
  //         }}
  //       >
  //         Force Logout
  //       </button>
  //     </>,
  //     {
  //       // hook will be called whent the component unmount
  //       // onClose: () => alert("Closed"),
  //     }
  //   );
  //   throw new Error(e);
  // });
};

export const GetAnnouncements = async (dispatch) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/all";
  await api
    .get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      if (response.data) {
        dispatch(setAnnouncements(response.data));
      }
    })
    .catch((e) => {
      console.error("GET Announcement Expection");
    });
};

export const BroadCast = async (props) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement";
  await api
    .post(url, props, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

export const DeleteAnnouncement = async (dispatch, id) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/delete";
  await api
    .post(url, id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      succesToast("Announcement Deleted");
      // console.log("ressssss", response.data);
    })
    .catch((e) => {
      console.error("Delete Announcement Expections", e);
    });
};

export const SearchAnnouncements = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "announcement/search";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setAnnouncements(data));
    })
    .catch((e) => {
      console.error("Get Announcements Expections", e);
    });
};

export const HandleNewComplaint = (dispatch, complaint, list = []) => {
  let newList = [];
  if (list) {
    if (list?.length > 0) {
      newList = [...list];
    }
  }
  newList.push(complaint);
  dispatch(setActiveComplaintList(newList));
};

export const HandleUpdateComplaint = (dispatch, complaint, list = []) => {
  let remove = false;
  if (
    complaint.state === 8 ||
    complaint.state === 9 ||
    complaint.discardBy ||
    complaint.deleted ||
    complaint.closedBy
  ) {
    remove = true;
  }

  let newList = [];
  let index = -1;
  if (remove) {
    newList = [...list];
    for (let i = 0; i < list.length; i++) {
      const element = { ...list[i] };
      if (element.id === complaint.id) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      newList.splice(index, 1);
    }
  } else {
    for (let i = 0; i < list.length; i++) {
      const element = { ...list[i] };
      if (element.id === complaint.id) {
        // element.statusId = alert.statusId;
        newList.push(complaint);
      } else {
        newList.push(element);
      }
    }
  }

  dispatch(setActiveComplaintList(newList));
  // }
};

export const ComplaintsListForChart = async (dispatch, data) => {
  const token = localStorage.getItem("token");
  const url = URL + "complaint/listForChart";
  await api
    .post(url, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      let data = response.data;
      dispatch(setChartComplaintList(data));
      let wireBreak = 0;
      let fenceAlarm = 0;
      let wireLoose = 0;
      let efigProblem = 0;
      let other = 0;

      const activeChartData = [];
      const closedChartData = [];
      const totalChartData = [];

      const active = data.filter((val) => (val.state < 5 ? true : false));
      const closed = data.filter((val) => (val.state >= 5 ? true : false));

      active?.forEach((val) => {
        if (val.title == "Wire Break") {
          wireBreak++;
        } else if (val.title == "Wire Loose") {
          wireLoose++;
        } else if (val.title == "Fence Alarm") {
          fenceAlarm++;
        } else if (val.title == "Efig Problem") {
          efigProblem++;
        } else if (val.title == "Other") {
          other++;
        }
      });
      if (wireBreak) {
        activeChartData.push({
          value: wireBreak,
          color: "#509cf2",
          name: "Wire Break",
        });
        wireBreak = 0;
      }
      if (wireLoose) {
        activeChartData.push({
          value: wireLoose,
          color: "#FDFF00",
          name: "Wire Loose",
        });
        wireLoose = 0;
      }
      if (fenceAlarm) {
        activeChartData.push({
          value: fenceAlarm,
          color: "#EFA41C",
          name: "Fence Alarm",
        });
        fenceAlarm = 0;
      }
      if (efigProblem) {
        activeChartData.push({
          value: efigProblem,
          color: "#808080",
          name: "EFIG Problem",
        });
        efigProblem = 0;
      }
      if (other) {
        activeChartData.push({
          value: other,
          color: "#90EE90",
          name: "Other",
        });
        other = 0;
      }

      closed?.forEach((val) => {
        if (val.title == "Wire Break") {
          wireBreak++;
        } else if (val.title == "Wire Loose") {
          wireLoose++;
        } else if (val.title == "Fence Alarm") {
          fenceAlarm++;
        } else if (val.title == "Efig Problem") {
          efigProblem++;
        } else if (val.title == "Other") {
          other++;
        }
      });
      if (wireBreak) {
        closedChartData.push({
          value: wireBreak,
          color: "#509cf2",
          name: "Wire Break",
        });
        wireBreak = 0;
      }
      if (wireLoose) {
        closedChartData.push({
          value: wireLoose,
          color: "#FDFF00",
          name: "Wire Loose",
        });
        wireLoose = 0;
      }
      if (fenceAlarm) {
        closedChartData.push({
          value: fenceAlarm,
          color: "#EFA41C",
          name: "Fence Alarm",
        });
        fenceAlarm = 0;
      }
      if (efigProblem) {
        closedChartData.push({
          value: efigProblem,
          color: "#808080",
          name: "EFIG Problem",
        });
        efigProblem = 0;
      }
      if (other) {
        closedChartData.push({
          value: other,
          color: "#90EE90",
          name: "Other",
        });
        other = 0;
      }

      data?.forEach((val) => {
        if (val.title == "Wire Break") {
          wireBreak++;
        } else if (val.title == "Wire Loose") {
          wireLoose++;
        } else if (val.title == "Fence Alarm") {
          fenceAlarm++;
        } else if (val.title == "Efig Problem") {
          efigProblem++;
        } else if (val.title == "Other") {
          other++;
        }
      });
      if (wireBreak) {
        totalChartData.push({
          value: wireBreak,
          color: "#509cf2",
          name: "Wire Break",
        });
        wireBreak = 0;
      }
      if (wireLoose) {
        totalChartData.push({
          value: wireLoose,
          color: "#FDFF00",
          name: "Wire Loose",
        });
        wireLoose = 0;
      }
      if (fenceAlarm) {
        totalChartData.push({
          value: fenceAlarm,
          color: "#EFA41C",
          name: "Fence Alarm",
        });
        fenceAlarm = 0;
      }
      if (efigProblem) {
        totalChartData.push({
          value: efigProblem,
          color: "#808080",
          name: "EFIG Problem",
        });
        efigProblem = 0;
      }
      if (other) {
        totalChartData.push({
          value: other,
          color: "#90EE90",
          name: "Other",
        });
        other = 0;
      }

      console.log(
        "activeeeeeeeee",
        activeChartData,
        totalChartData,
        closedChartData
      );

      dispatch(setActiveComplaintDataChart(activeChartData));
      dispatch(setTotalComplaintDataChart(totalChartData));
      dispatch(setClosedComplaintDataChart(closedChartData));
    })
    .catch((e) => {
      console.error("Search complaint list for chart Expections");
      errorToast(e.response.data.err);
    });
};

export const SendResetLink = async (dispatch, data) => {
  const url = URL + "user/sendResetLink";
  await api
    .post(url, data)
    .then((response) => {
      // dispatch(setSearchedDevices(data));
      succesToast("Password reset link sent to provided number or email");
    })
    .catch((e) => {
      console.error("error send link", e);
    });
};

export const ResetPasswordAPI = async (dispatch, data) => {
  const url = URL + "user/resetPassword";
  await api
    .post(url, data)
    .then((response) => {
      // dispatch(setSearchedDevices(data));
      // succesToast("Password reset link sent to provided number or email");
      return response;
    })
    .catch((e) => {
      // console.error("error send link", e);
      // alert("here");
      throw new Error(e);
    });
};
