/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";

import {
  setEfig,
  setEfigLoading,
  setUpdate,
} from "../../../../../redux/slices/efigSlice";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";
import { EFIGAlarmDetailsApi } from "../../../../../actions";
TimeAgo.addLocale(en);
var arr = [];

const EfigAlarmDetails = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const [fw, setFw] = useState("");

  // const  = useSelector((state) => state.efig.efig);
  const efig = useSelector((state) => state.efig.tempEfig);
  const update = useSelector((state) => state.efig.update);
  const alarmHistory = useSelector((state) => state.efig.alarmHistory);
  const logHistory = useSelector((state) => state.efig.logHistory);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (efig && user && update) {
      getAlarmHistory();
    }
  }, [efig, user, props, update]);

  const getAlarmHistory = async () => {
    // dispatch(setEfigLoading(true));
    if (efig?.userId && efig?.id) {
      await EFIGAlarmDetailsApi(dispatch, {
        userId: efig?.userId,
        energizerId: efig?.id,
      });
    }
    dispatch(setEfigLoading(false));
  };

  const getLogList = () => {
    // console.log("debug list ::: ", logHistory);
    return logHistory?.map((val) => (
      <tr key={val.id}>
        <td>
          {val?.des == "ES11"
            ? "Energizer Armed"
            : val?.des == "ES10"
            ? "Energizer Disarmed"
            : val?.des == "Command change ES1"
            ? "Command Arm Energizer"
            : val?.des == "Command change ES0"
            ? "Command Disarm Energizer"
            : ""}
        </td>
        <td>
          {new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
      </tr>
    ));
  };

  const getList = (alarmStr, alr1 = "", alr2 = "") => {
    const list = alarmHistory?.filter(
      (val) => val?.type === alr1 || val?.type === alr2
    );
    return list?.map((val) => (
      <tr key={val.id}>
        <td>{val?.type}</td>
        <td>
          {new Date(val?.createdAt).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Modal
        title={"EFIG Alarm Details"}
        name={"efigAlarmDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        {alarmHistory ? (
          <>
            <div className="col-12">
              <a href="#" className="nav-link">
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To List"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#efigDetails"}
                  onClick={() => {
                    dispatch(setEfig(efig));
                    dispatch(setUpdate(false));
                  }}
                ></i>
              </a>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center className="sticky">
                  <u>
                    <h5>Fence Alarm</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>
                    {getList(
                      "Fence Alarm",
                      "Fence Alarm",
                      "Fence Alarm Cleared"
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center>
                  <u>
                    <h5 className="sticky-top">AC Fail</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>
                    {getList("AC Fail", "AC Mains NORMAL", "AC Fail")}
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center>
                  <u>
                    <h5>Internet Alarms</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>
                    {getList(
                      "Internet Alarms",
                      "Internet DISCONNECTED, please check WiFi",
                      "Internet CONNECTED"
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center>
                  <u>
                    <h5>Low Battery Alarm</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>
                    {getList("Low Battery Alarm", "Low Battery Alarm")}
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center>
                  <u>
                    <h5>Tamper Alarm / Low Power History</h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>
                    {getList("Tamper Alarm", "TAMPERING clear", "Tamper Alarm")}
                    {getList(
                      "Low Power History",
                      "Energizer in HIGH VOLTAGE mode",
                      "Low Power Mode"
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="col-lg-4"
                style={{
                  height: "38vh",
                  overflowY: "auto",
                  border: "1px solid lightgrey",
                }}
              >
                <center>
                  <u>
                    <h5>Arm / Disarm Details </h5>
                  </u>
                </center>
                <table className="table table-hover">
                  <tbody>{getLogList()}</tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default EfigAlarmDetails;
