import { Buffer } from "buffer";

// let apiUrl = `https://efig.ears.pk/api/`;
// let baseUrl = `https://efig.ears.pk`;
// let mqttUrl = `wss://efig.ears.pk/mqtt/`;
// export const erpUrl = "https://test.unisourceinternational.com/";

let apiUrl = "https://efig.unisourceinternational.com/api/";
let baseUrl = "https://efig.unisourceinternational.com";
let mqttUrl = "wss://efig.unisourceinternational.com/mqtt/";
export const erpUrl = "https://erp.unisourceinternational.com/";

if (window.location.host.indexOf("localhost") === 0) {
  // apiUrl = "https://efig.ears.pk/api/";
  // mqttUrl = "wss://dev.ears.pk/mqtt/";
  mqttUrl = "wss://efig.ears.pk/mqtt/";
  apiUrl = `https://efig.ears.pk/api/`;
  // apiUrl = "http://192.168.0.108:3001/";
  // apiUrl = "http://192.168.100.9:3001/";
}

console.log("CONFIG BASE_URL", apiUrl);
console.log("CONFIG MQTT_URL", mqttUrl);

export const URL = apiUrl;
export const MQTT_URL = mqttUrl;
export const BASE_URL = baseUrl;

// export const EFIG_WEB_JWT_PUBKEY = Buffer.from(
//   process.env.EFIG_WEB_JWT_PUBKEY,
//   "base64"
// );
